import React from 'react'

export default {
  a: {
    title: 'Introduction',
    content: (
      <>
        <p>
          With constant evolution comes{' '}
          <span className="font-medium">necessary change</span>.
        </p>
        <p>
          After years of longlining with hooks, the Kawatea now{' '}
          <br className="hidden sm:inline" /> employs a state of the art pot
          fishing method, allowing for <br className="hidden sm:inline" /> the
          highest standards of sustainable harvesting.
        </p>
      </>
    ),
  },
  b: {
    title: 'Fishing Method',
    content: (
      <>
        <p>
          The Kawatea begins by deploying a <br className="hidden sm:inline" />
          <span className="font-medium">flag pole and two buoys</span>, marking
          the <br className="hidden sm:inline" />
          beginning of the set.
        </p>
        <p>
          An anchor is then deployed, sending the{' '}
          <br className="hidden sm:inline" />
          <span className="font-medium">mainline down to the seabed</span> and
          setting <br className="hidden sm:inline" /> the trajectory for the
          pots to follow.
        </p>
      </>
    ),
  },
  c: {
    title: 'Step 1',
    content: (
      <p>
        The pots are then attached to the mainline{' '}
        <br className="hidden sm:inline" /> in a closed form. These are spaced
        every <br className="hidden sm:inline" />{' '}
        <span className="font-medium">50 metres</span>, allowing the crew to
        safely <br className="hidden sm:inline" /> manage the process of
        setting.
      </p>
    ),
  },
  d: {
    title: 'Step 2',
    content: (
      <p>
        As the pot leaves the vessel it begins{' '}
        <br className="hidden sm:inline" /> to open,{' '}
        <span className="font-medium">expanding completely</span> by the{' '}
        <br className="hidden sm:inline" /> time it hits the water. The pot then{' '}
        <br className="hidden sm:inline" />
        follows the mainline down to rest lightly{' '}
        <br className="hidden sm:inline" /> on the seabed.
      </p>
    ),
  },
  e: {
    title: 'Protecting Sea Birds',
    content: (
      <>
        <p>
          Our pot fishing method has revolutionised the way we{' '}
          <br className="hidden sm:inline" />
          <span className="font-medium">interact with seabirds</span>, with the
          Kawatea using no bird <br className="hidden sm:inline" /> mitigation
          devices such as lasers and tori lines.
        </p>
        <p>
          During the process of both setting and hauling, we have{' '}
          <br className="hidden sm:inline" /> harmed{' '}
          <span className="font-medium">Zero seabirds</span>.
        </p>
      </>
    ),
  },
  f: {
    title: 'Mammal Interaction',
    content: (
      <>
        <p>
          Unfortunately, Marine mammals are another group{' '}
          <br className="hidden sm:inline" /> of species impacted by traditional
          fishing methods.
        </p>
        <p>
          Our pots feature an entirely enclosed{' '}
          <span className="font-medium">bait device</span>,{' '}
          <br className="hidden sm:inline" /> making it{' '}
          <span className="font-medium">impossible for mammals</span> to get
          close to <br className="hidden sm:inline" /> and be harmed.
        </p>
      </>
    ),
  },
  g: {
    title: 'No Bycatch',
    content: (
      <p>
        Traditional fishing methods have always struggled{' '}
        <br className="hidden sm:inline" /> with selective harvesting, impacting
        unwanted fish <br className="hidden sm:inline" /> species in the process
        of targeting the one they <br className="hidden sm:inline" /> want.{' '}
        <span className="font-medium">Our pots are entirely selective</span>,{' '}
        appealing <br className="hidden sm:inline" /> only to the fish we target
        while harming none of <br className="hidden sm:inline" /> the fish we
        don’t.
      </p>
    ),
  },
  h: {
    title: 'Minimal Disturbance to the Ocean Floor',
    content: (
      <p>
        Without our ocean floor habitats intact, fish{' '}
        <br className="hidden sm:inline" /> populations suffer greatly. Unlike
        traditional fishing <br className="hidden sm:inline" /> methods, our
        pots have{' '}
        <span className="font-medium">
          minimal footprint on the <br className="hidden sm:inline" /> seabed
        </span>
        . Weighing only 7kg on land, when <br className="hidden sm:inline" />{' '}
        submerged our pots are almost weightless.
      </p>
    ),
  },
  i: {
    title: 'Less Stressed Fish',
    content: (
      <p>
        Unlike traditional fishing methods, all fish that we{' '}
        <br className="hidden sm:inline" /> bring onboard are live. Protected
        within the pot <br className="hidden sm:inline" /> and safe from outside
        elements, the result is a <br className="hidden sm:inline" />{' '}
        <span className="font-medium">less stressed fish</span> and ultimately
        an end product of the{' '}
        <span className="font-medium">highest quality</span>.
      </p>
    ),
  },
}
