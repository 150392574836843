import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'

import ScrollLink from '../components/Ui/ScrollLink'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import PotFishing from '../components/PotFishing'
import Stats from '../components/ContentBlocks/ContentBlocksStats'
import Hero from '../components/Hero'

const footerLinks = ['traceability', 'the-kawatea', 'past']

class PotFishingPage extends React.Component {
  state = {
    scrollToValue: Number,
  }
  componentDidMount = () => {
    this.setState({
      scrollToValue: window.innerHeight * 1.25,
    })
  }
  render() {
    const { location, data } = this.props
    const page = data.prismicPotFishing.data

    return (
      <Layout>
        <SEO
          title={page.meta_title}
          description={page.meta_description}
          location={location}
        />
        <div className="absolute top-0 inset-x-0 z-10">
          <Hero image={data.heroImage.childImageSharp.fluid} />
        </div>
        <div className="gutters h-screen relative pt-75 z-20">
          <div className="lg:mx-1/24">
            <Fade delay={225} bottom distance={'25px'} duration={2250}>
              <div className="max-w-md mb-12">
                <h2 className="text-xs uppercase tracking-widest mb-2">
                  Our Method
                </h2>
                <h1 className="text-3xl md:text-6xl uppercase font-medium tracking-wide mb-4">
                  Pot Fishing
                </h1>
                <p className="text-lg md:text-2xl font-light mb-12">
                  Transforming the Future.
                  <br />
                  Respecting the past.
                </p>
                <ScrollLink to={this.state.scrollToValue} text="Learn Below" />
              </div>
            </Fade>
          </div>
        </div>
        <PotFishing />
        <div className="mb-32">
          <div className="gutters">
            <div className="large-card-grid">
              <div className="large-card-grid__card">
                <div className="large-card-grid__card__image">
                  <Img fluid={data.pastImage.childImageSharp.fluid} alt="" />
                </div>

                <h3 className="type-upper-xs mb-6">Past</h3>
                <div className="rte lg:text-2xl">
                  <p>
                    The Hinaki Waharua was a weaved pot used in rivers, lakes
                    and estuaries by the indigenous Māori people of New Zealand.
                  </p>
                  <p>
                    A symbol of{' '}
                    <span className="font-medium">traditional knowledge</span>{' '}
                    and its effectiveness, the simplistic design allowed for the{' '}
                    <span className="font-medium">
                      sustainable and controlled harvest
                    </span>{' '}
                    of fish from fresh waterways.
                  </p>
                </div>
              </div>

              <div className="large-card-grid__card">
                <div className="large-card-grid__card__image">
                  <Img fluid={data.presentImage.childImageSharp.fluid} alt="" />
                </div>

                <h3 className="type-upper-xs mb-6">Present</h3>
                <div className="rte lg:text-2xl">
                  <p>
                    The pots we utilise can be seen as a modern version of the
                    Hinaki Waharua;
                  </p>
                  <p>
                    <span className="font-medium">
                      Fully collapsible, lightweight and strong,
                    </span>{' '}
                    these pots allow us to sustainably harvest just like our
                    ancestors did.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Stats />
        <Footer links={footerLinks} />
      </Layout>
    )
  }
}

PotFishingPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pagePotFishingQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/pot-fishing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pastImage: file(relativePath: { eq: "pot-fishing/pot-fishing-past.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    presentImage: file(
      relativePath: { eq: "pot-fishing/pot-fishing-present.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicPotFishing {
      data {
        meta_title
        meta_description
      }
    }
  }
`

export default PotFishingPage
