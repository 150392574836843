import React from 'react'
import lottie from 'lottie-web'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import textItems from './textItems'

gsap.registerPlugin(ScrollTrigger)

const syncLottieAnimations = (
  playhead,
  primaryAnimation,
  secondaryAnimation
) => {
  const { frame } = playhead
  primaryAnimation.goToAndStop(frame, true)

  const relativeFrame =
    (secondaryAnimation.totalFrames / primaryAnimation.totalFrames) * frame
  secondaryAnimation.goToAndStop(relativeFrame, true)
}
class PotFishing extends React.Component {
  constructor(props) {
    super(props)
    this.timelineRef = React.createRef()
    this.parentContainerRef = React.createRef()
    this.vesselContainerRef = React.createRef()
    this.compassContainerRef = React.createRef()
    this.underwaterContainerRef = React.createRef()
    this.textItemsRef = React.createRef()
  }

  state = {
    vesselAnimation: Object,
    compassAnimation: Object,
    underwaterAnimation: Object,
  }

  loadAnimations() {
    const params = {
      renderer: 'svg',
      loop: false,
      autoplay: false,
    }

    const vesselAnimation = lottie.loadAnimation({
      ...params,
      wrapper: this.vesselContainerRef.current,
      path: '/lottie-pot-fishing-vessel.json',
    })

    vesselAnimation.addEventListener('DOMLoaded', () => {
      this.setState({ vesselAnimation })
    })

    const compassAnimation = lottie.loadAnimation({
      ...params,
      wrapper: this.compassContainerRef.current,
      path: '/lottie-pot-fishing-compass.json',
    })

    compassAnimation.addEventListener('DOMLoaded', () => {
      this.setState({ compassAnimation })
    })

    const underwaterAnimation = lottie.loadAnimation({
      ...params,
      wrapper: this.underwaterContainerRef.current,
      path: '/lottie-pot-fishing-underwater.json',
    })

    underwaterAnimation.addEventListener('DOMLoaded', () => {
      this.setState({ underwaterAnimation })
    })
  }

  destroyAnimations() {
    const {
      vesselAnimation,
      compassAnimation,
      underwaterAnimation,
    } = this.state
    ;[vesselAnimation, compassAnimation, underwaterAnimation].forEach(
      animation => animation.destroy()
    )
  }

  createTimeline() {
    const {
      vesselAnimation,
      compassAnimation,
      underwaterAnimation,
    } = this.state

    if (
      !vesselAnimation.isLoaded ||
      !compassAnimation.isLoaded ||
      !underwaterAnimation.isLoaded ||
      this.timelineRef.current
    )
      return

    const parentContainerEl = this.parentContainerRef.current
    const vesselContainerEl = this.vesselContainerRef.current
    const compassContainerEl = this.compassContainerRef.current
    const underwaterContainerEl = this.underwaterContainerRef.current
    const textItemsEls = this.textItemsRef.current

    gsap.set([underwaterContainerEl, ...Object.values(textItemsEls)], {
      opacity: 0,
    })

    const observer = ScrollTrigger.observe({
      target: parentContainerEl,
      type: 'wheel,touch,pointer',
      wheelSpeed: -1, // Normalises scroll direction for wheel, touch
      tolerance: 100,
      preventDefault: true,
      onPress: self => {
        // Prevents scrolling on touch
        ScrollTrigger.isTouch && self.event.preventDefault()
      },
      onUp: () =>
        mainTimeline.totalProgress() === 1
          ? observer.disable()
          : mainTimeline.play(),
      onDown: () =>
        mainTimeline.totalProgress() === 0
          ? observer.disable()
          : mainTimeline.reverse(),
    })
    observer.disable()

    ScrollTrigger.create({
      trigger: parentContainerEl,
      start: 'center center',
      end: 'center center',
      onEnter: () => observer.enable(),
      onEnterBack: () => observer.enable(),
    })

    const mainTimeline = gsap.timeline({
      paused: true,
      onComplete: () => observer.disable(),
      onReverseComplete: () => observer.disable(),
    })

    const vesselPlayhead = { frame: 0 }
    const vesselTimeline = gsap
      .timeline({ defaults: { duration: 0.6, ease: 'power1.inOut' } })
      .to(vesselPlayhead, {
        duration: 216 / 60,
        frame: 216,
        ease: 'power1.out',
        onUpdate: syncLottieAnimations,
        onUpdateParams: [vesselPlayhead, vesselAnimation, compassAnimation],
      })
      .to(textItemsEls.a, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.a, { opacity: 0 })
      .to(vesselPlayhead, {
        duration: (474 - 216) / 60,
        frame: 474,
        ease: 'none',
        onUpdate: syncLottieAnimations,
        onUpdateParams: [vesselPlayhead, vesselAnimation, compassAnimation],
      })
      .to(textItemsEls.b, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.b, { opacity: 0 })
      .to(vesselPlayhead, {
        duration: (726 - 472) / 60,
        frame: 726,
        ease: 'none',
        onUpdate: syncLottieAnimations,
        onUpdateParams: [vesselPlayhead, vesselAnimation, compassAnimation],
      })
      .to(textItemsEls.c, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.c, { opacity: 0 })
      .to(vesselPlayhead, {
        duration: (766 - 726) / 60,
        frame: 766,
        ease: 'none',
        onUpdate: syncLottieAnimations,
        onUpdateParams: [vesselPlayhead, vesselAnimation, compassAnimation],
      })
      .to(textItemsEls.d, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.d, { opacity: 0 })
      .to(vesselPlayhead, {
        duration: (vesselAnimation.totalFrames - 1 - 766) / 60,
        frame: vesselAnimation.totalFrames - 1,
        ease: 'none',
        onUpdate: syncLottieAnimations,
        onUpdateParams: [vesselPlayhead, vesselAnimation, compassAnimation],
      })
      .to(textItemsEls.e, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to([textItemsEls.e, vesselContainerEl, compassContainerEl], {
        opacity: 0,
      })

    const underwaterPlayhead = { frame: 0 }
    const underwaterTimeline = gsap
      .timeline({ defaults: { duration: 0.6, ease: 'power1.inOut' } })
      .to(underwaterContainerEl, {
        opacity: 1,
      })
      .to(underwaterPlayhead, {
        duration: 140 / 60,
        frame: 140,
        ease: 'none',
        onUpdate: () =>
          underwaterAnimation.goToAndStop(underwaterPlayhead.frame, true),
      })
      .to(underwaterPlayhead, {
        duration: (270 - 140) / 60,
        frame: 270,
        ease: 'none',
        onUpdate: () =>
          underwaterAnimation.goToAndStop(underwaterPlayhead.frame, true),
      })
      .to(textItemsEls.f, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.f, { opacity: 0 })
      .to(textItemsEls.g, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.g, { opacity: 0 })
      .to(underwaterPlayhead, {
        duration: (400 - 270) / 60,
        frame: 400,
        ease: 'none',
        onUpdate: () =>
          underwaterAnimation.goToAndStop(underwaterPlayhead.frame, true),
      })
      .to(textItemsEls.h, { opacity: 1 })
      .add(() => mainTimeline.pause())
      .to(textItemsEls.h, { opacity: 0 })
      .to(underwaterPlayhead, {
        duration: (underwaterAnimation.totalFrames - 1 - 400) / 60,
        frame: underwaterAnimation.totalFrames - 1,
        ease: 'none',
        onUpdate: () =>
          underwaterAnimation.goToAndStop(underwaterPlayhead.frame, true),
      })
      .to(textItemsEls.i, { opacity: 1 })

    mainTimeline.add(vesselTimeline).add(underwaterTimeline)

    this.timelineRef.current = mainTimeline

    parentContainerEl.classList.add('pot-fishing--initialised')
  }

  componentDidMount() {
    this.loadAnimations()
  }

  componentDidUpdate() {
    this.createTimeline()
  }

  componentWillUnmount() {
    this.timelineRef.current.kill()
    this.destroyAnimations()
  }

  render() {
    return (
      <div className="pot-fishing" ref={this.parentContainerRef}>
        <div className="pot-fishing__text">
          {Object.entries(textItems).map(([key, { title, content }]) => (
            <div
              className={`pot-fishing__text__item pot-fishing__text__item--${key}`}
              ref={el => {
                const current = this.textItemsRef.current || {}
                current[key] = el
                this.textItemsRef.current = current
              }}
              key={key}
            >
              <h2 className="type-upper-xs mb-6">{title}</h2>
              <div className="rte">{content}</div>
            </div>
          ))}
        </div>

        <div className="pot-fishing__anims">
          <div
            className="pot-fishing__anims__vessel"
            ref={this.vesselContainerRef}
          />
          <div
            className="pot-fishing__anims__compass"
            ref={this.compassContainerRef}
          />

          <div
            className="pot-fishing__anims__underwater"
            ref={this.underwaterContainerRef}
          />
        </div>
      </div>
    )
  }
}

export default PotFishing
