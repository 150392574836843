import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import CountUp from 'react-countup'

import Button from '../Ui/Button'

import posterCalm from '../../images/posters/contet-blocks-tracking-calm.jpg'
import posterMild from '../../images/posters/contet-blocks-tracking-mild.jpg'
import posterRough from '../../images/posters/contet-blocks-tracking-rough.jpg'

const ContentBlocksStats = ({ hideButton, data }) => {
  const [direction, updateDirection] = useState(0)
  const [temperature, updateTemperature] = useState(-99)
  const [windSpeed, updateWindSpeed] = useState(99)
  const [swell, updateSwell] = useState(99)

  const videos = {
    calm: {
      video:
        'https://player.vimeo.com/external/368394167.hd.mp4?s=a3573a16a6f1f9371fb3c4dcf4be411e186a4254&profile_id=174',
      poster: posterCalm,
    },
    mild: {
      video:
        'https://player.vimeo.com/external/368373570.hd.mp4?s=a1b716fddb510eb712bc2b3818d1f808c9fd06fb&profile_id=174',
      poster: posterMild,
    },
    rough: {
      video:
        'https://player.vimeo.com/external/368394002.hd.mp4?s=06f383d9d5dcf7c1b6629947f47bbf57d88f9009&profile_id=174',
      poster: posterRough,
    },
  }

  const tracking = data.prismicTracking.data
  const condition = tracking.conditions.toLowerCase()

  const setTrackingData = () => {
    updateDirection(tracking.direction_value)
    updateTemperature(tracking.temperature)
    updateWindSpeed(tracking.wind_speed)
    updateSwell(tracking.swell)
  }

  return (
    <Fade duration={2250} onReveal={() => setTrackingData()}>
      <div className="relative md:ratio md:ratio--1 lg:ratio--5/9">
        <div className="md:ratio__content">
          <div className="absolute z-0 w-full h-full">
            <div className="absolute inset-0 z-20 bg-blue-800-opaque-25"></div>
            <div
              className="relative w-full h-full z-10"
              dangerouslySetInnerHTML={{
                __html: `
              <video
                class="w-full h-full object-cover"
                muted
                autoplay
                playsinline
                loop
                poster="${videos[condition].poster}"
                src="${videos[condition].video}"
              />`,
              }}
            />
          </div>
          <div className="gutters h-full w-full flex justify-center items-center py-20 md:py-0">
            <div className="w-full lg:w-20/24 lg:mx-auto text-center relative z-10">
              <h4 className="type-upper-xs leading-none mb-16 lg:mb-20">
                The difference of pot fishing
              </h4>
              <div className="md:flex mb-16 lg:mb-20 leading-none">
                <div className="md:w-1/3 mx-auto md:mx-0 mb-16 md:mb-0">
                  <div className="font-medium mb-6">
                    Reduction in bycatch vs hook fishing
                  </div>
                  <div className="text-7xl lg:text-8xl font-thin tracking-tightest">
                    <CountUp
                      start={0}
                      end={85}
                      useEasing
                      enableScrollSpy
                      scrollSpyOnce
                    />
                    %
                  </div>
                </div>
                <div className="md:w-1/3 mx-auto md:mx-0 mb-16 md:mb-0">
                  <div className="font-medium mb-6">
                    Reduction of CO2 emissions
                  </div>
                  <div className="text-7xl lg:text-8xl font-thin tracking-tightest">
                    <CountUp
                      start={0}
                      end={45}
                      delay={0.25}
                      useEasing
                      enableScrollSpy
                      scrollSpyOnce
                    />
                    %
                  </div>
                </div>
                <div className="md:w-1/3 mx-auto md:mx-0 mb-16 md:mb-0">
                  <div className="font-medium mb-6">
                    Threat to birds or mammals
                  </div>
                  <div className="text-7xl lg:text-8xl font-thin tracking-tightest">
                    <CountUp
                      start={100}
                      end={0}
                      delay={0.5}
                      useEasing
                      enableScrollSpy
                      scrollSpyOnce
                    />
                  </div>
                </div>
              </div>
              {!hideButton && (
                <Button link="traceability" linkText="More Info" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismicTracking {
          data {
            conditions
            direction
            direction_value
            swell
            temperature
            wind_speed
          }
        }
      }
    `}
    render={data => <ContentBlocksStats data={data} />}
  />
)
